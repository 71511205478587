import React, { useState } from 'react';

const ImageGallery = () => {
    const openPopup = (imageSrc) => {
        document.getElementById('popupImage').src = imageSrc;
        document.getElementById('popup').style.display = 'flex';
      };
    
      const closePopup = () => {
        document.getElementById('popup').style.display = 'none';
      };
    
    
    
      const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div className="image-grid-container">
        {[...Array(20)].map((_, index) => (
          <a href={`assets/images/ecommerce/website-images/Construction/${index + 1}.png`} target='_blank'>
            <img
            key={index}
            src={`assets/images/ecommerce/website-images/Construction/${index + 1}.png`}
            className="image-grid-item"
            alt={`Image ${index}`}
            // onClick={() => openPopup(`assets/images/ecommerce/website-images/Construction/${index + 1}.png`)}
          />
          </a>
        ))}
      </div>

      {/* Popup */}
      <div className="popup" id="popup">
        <img id="popupImage" src="" alt="Zoomed Image" />
        <span
          onClick={closePopup}
          style={{
            position: 'fixed',
            top: 40,
            right: 40,
            color: '#fff',
            cursor: 'pointer',
            fontSize: 40
          }}
        >
          ×
        </span>
      </div>
    </div>
  );
};

export default ImageGallery;
