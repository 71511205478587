import { createSlice } from '@reduxjs/toolkit';
import toast from "react-hot-toast";


const initialState = { products: [] }

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        productsAdded(state, action) {
            state.products = action.payload ?? [];
        },
    },
})

export const { productsAdded } = productSlice.actions
export default productSlice.reducer
