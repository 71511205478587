import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import $ from "jquery";



const Web = () => {


    useEffect(() => {
        // Trigger the click event on the first tab button
        document.getElementById("defaultOpen").click();
    }, []);

    const openCity = (evt, cityName) => {
        const tabcontentweb = document.getElementsByClassName("tabcontentweb");
        for (let i = 0; i < tabcontentweb.length; i++) {
            tabcontentweb[i].style.display = "none";
        }
        const tablinks = document.getElementsByClassName("tablinks");
        for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].classList.remove("active");
        }
        const cityElement = document.getElementById(cityName);
        if (cityElement) {
            cityElement.style.display = "block";
            evt.currentTarget.classList.add("active");
        }
    };


    return (
        <>
            <div
                className="service-screen-section1"
                style={{ backgroundImage: "url(assets/images/gifs/web.gif)" }}
            >
                <header>
                    <nav className="navbar">
                        <Link to='/'>
                            <div className="logo">
                                <img src="assets/images/menu/logo.png" height="61px" alt="" />
                            </div>
                        </Link>
                        <Link to='/menu'>
                            <div className="more">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={47}
                                    height={10}
                                    viewBox="0 0 47 10"
                                    fill="none"
                                >
                                    <path
                                        d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </nav>
                </header>
                <div className="service-screen-sec1-content">
                    <h3>SERVICES / Web development</h3>
                    <div
                        style={{
                            height: 1,
                            width: "100%",
                            backgroundColor: "#FFF",
                            marginTop: 4
                        }}
                    />
                    <h1>
                        Your Vision,
                        <br /> Your Action
                    </h1>
                    <Link to='/enquiry-form'>
                        <div
                            className="row align-items-center mt-4 get-touch">
                            <div className="col-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={46}
                                    height={41}
                                    viewBox="0 0 46 41"
                                    fill="none"
                                >
                                    <rect
                                        x={5}
                                        width={41}
                                        height={41}
                                        rx="20.5"
                                        fill="#51868C"
                                        fillOpacity="0.5"
                                    />
                                    <rect width={41} height={41} rx="20.5" fill="white" />
                                    <path
                                        d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                        fill="#51868C"
                                    />
                                    <path
                                        d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                        fill="#51868C"
                                    />
                                </svg>
                            </div>
                            <div className="col-9">
                                <span
                                    style={{
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Roboto Mono",
                                        fontSize: 18,
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                    }}
                                >
                                    Get in touch
                                </span>
                            </div>
                        </div>
                    </Link>
                    <div className="get-touch-res">
                        <Link to='/enquiry-form'>
                            <button>
                                Get in touch
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* home section end */}



            {/* web content start*/}
            <div className='web-screen-content'>
                <div className="web-content-top">
                    <div className="web-content-top-right">
                        <h1>About <br /> Web Development</h1><br />
                        <p>Web development is the process of creating and maintaining websites, encompassing everything from crafting a visually appealing user interface to coding and database management. <br /><br /> It involves a combination of programming, design, and server management to bring a website to life. It is the intricate process of creating and maintaining websites, covering a spectrum from crafting visually captivating user interfaces to the intricate workings of coding and database management. It requires a harmonious fusion of programming languages, design principles, and adept server management to breathe life into a website.</p>
                    </div>
                    <div className="web-content-top-left">
                        <img src="assets/images/web/content-img.png" alt="" />
                    </div>
                </div>
                <div className="web-content-types">
                    <h1>Types of Websites</h1>
                    <div className="row align-items-start">
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/web/landing.png" alt="" />
                                <h2>Landing Page</h2>
                                <p>These are meticulously crafted web pages designed to captivate and convert visitors swiftly. They serve as the initial point of contact and are optimized for specific marketing campaigns or promotions.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/web/static.png" alt="" />
                                <h2>Static Website</h2>
                                <p>Ideal for businesses seeking simplicity and rapid loading times, static websites offer a straightforward display of information without the need for complex database interactions. They are perfect for showcasing essential details about a company, such as contact information or service offerings.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/web/dynamic.png" alt="" />
                                <h2>Dynamic Website</h2>
                                <p>Dynamic websites are dynamic in nature, allowing for frequent content updates, user interactivity, and scalability for future expansion. They are well-suited for businesses requiring regular updates, such as news portals, e-commerce platforms, or community forums.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* web content end*/}




            {/* web type start */}
            <div className="web-type">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <h1>Website</h1>
                            <div className="tab-web">
                                <button
                                    className="tablinks"
                                    onClick={(evt) => openCity(evt, 'landing')}
                                    id="defaultOpen"
                                >
                                    <h2>01</h2>
                                    <h4>Landing Page</h4>
                                </button>
                                <button
                                    className="tablinks"
                                    onClick={(evt) => openCity(evt, 'static')}
                                    id="static-btn"
                                >
                                    <h2>02</h2>
                                    <h4>Static Website</h4>
                                </button>
                                <button
                                    className="tablinks"
                                    onClick={(evt) => openCity(evt, 'dynamic')}
                                    id="dynamic-btn"
                                >
                                    <h2>03</h2>
                                    <h4>Dynamic Website</h4>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div id="landing" className="tabcontentweb">
                                <div className="row text-center">
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/grocery.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Main Grocery</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/garments.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Business</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/fruits.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Fruits</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/footware.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Main Footware</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/finance.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Finance</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/electronics.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Electronics</h5>
                                        </a>
                                    </div>
                                </div>
                                <Link to='/landing'>
                                    <div className="row text-center" >
                                        <div className="col">
                                            <button className='web-button'>Read More</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div id="static" className="tabcontentweb">
                                <div className="row text-center">
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/dairy.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Main Dairy</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/cosmetic.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Cosmetic</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/consultant.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Consultant</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/construction.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Main Construction</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/chemical.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Chemical</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/ecommerce/website/books.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Books</h5>
                                        </a>
                                    </div>
                                </div>
                                <Link to="/static">
                                    <div className="row text-center" >
                                        <div className="col">
                                            <button className='web-button'>Read More</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div id="dynamic" className="tabcontentweb">
                                <div className="row text-center">
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/dynamic/websites/corporate/1.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Main Corporate</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/dynamic/websites/entertainment/1.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Business</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/dynamic/websites/entertainment/2.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Entertainment</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/dynamic/websites/furniture/1.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Furniture</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/dynamic/websites/social/1.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Home Social</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 ">
                                        <a href="#" className="container-animation">
                                            <div className="demo-item">
                                                <img
                                                    className="s-img-switch"
                                                    width="100%"
                                                    src="assets/images/dynamic/websites/technology/1.png"
                                                    alt="example"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href="#"
                                            className="service-ani-text"
                                            style={{ color: "#000" }}
                                        >
                                            <h5>Main Technology</h5>
                                        </a>
                                    </div>
                                </div>
                                <Link to="/dynamic">
                                    <div className="row text-center" >
                                        <div className="col">
                                            <button className='web-button'>Read More</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            
            
            {/* contact us start */}
            <section className="info-section section-b-space">
                <div className="container">
                    <div
                        className="info-box"
                        style={{
                            border: "none",
                            backgroundImage: "url(assets/images/service/footer.svg)",
                            textAlign: "start"
                        }}
                        data-aos="fade-in"
                        data-aos-duration={1000}
                        data-aos-delay={100}
                    >
                        <h2 style={{ fontFamily: "Inter", marginBottom: 5 }}>
                            Transformation starts here
                        </h2>
                        <p style={{ width: "100%", fontFamily: "Inter" }}>
                            let's build your vision.
                        </p>
                        <Link to='/enquiry-form'>
                            <div className="row align-items-center mt-4 mb-4 get-touch">
                                <div className="col-md-auto">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={46}
                                        height={41}
                                        viewBox="0 0 46 41"
                                        fill="none"
                                    >
                                        <rect
                                            x={5}
                                            width={41}
                                            height={41}
                                            rx="20.5"
                                            fill="#51868C"
                                            fillOpacity="0.5"
                                        />
                                        <rect width={41} height={41} rx="20.5" fill="white" />
                                        <path
                                            d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                            fill="#51868C"
                                        />
                                        <path
                                            d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                            fill="#51868C"
                                        />
                                    </svg>
                                </div>
                                <div className="col-md-auto">
                                    <span
                                        style={{
                                            color: "#FFF",
                                            textAlign: "center",
                                            fontFamily: "Roboto Mono",
                                            fontSize: 18,
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Get in touch
                                    </span>
                                </div>
                            </div>
                        </Link>
                        <div className="get-touch-res">
                            <Link to='/enquiry-form'>
                                <button>
                                    Get in touch
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact us end */}
            {/* footer section start*/}
            <footer>
                <div className="container">
                    <div className="footer-row">
                        <div className="footer-main">
                            <a href="#" className="footer-logo">
                                <img src="#" className="img-fluid" alt="" />
                            </a>
                            <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                            <form>
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter your mail"
                                    />
                                    <a href="#" data-cursor="pointer" className="btn-basic">
                                        Subscribe
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Quick Link</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to='/'>
                                            <a data-cursor="pointer" href="#">
                                                Home
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/services'>
                                            <a data-cursor="pointer" href="#">
                                                Service
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <a data-cursor="pointer" href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <Link to='/enquiry-form'>
                                            <a data-cursor="pointer" href="#">
                                                Contact us
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our service</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/web">
                                            <a data-cursor="pointer" href="#">
                                                Web Development
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ecommerce">
                                        <a data-cursor="pointer" href="#">
                                            E-commerce Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/software">
                                        <a data-cursor="pointer" href="#">
                                            Software Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ui-ux">
                                        <a data-cursor="pointer" href="#">
                                            UI & UX
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our company</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/need-consultation">
                                        <a data-cursor="pointer" href="#">
                                            Need Consultation
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/who-are-we">
                                        <a data-cursor="pointer" href="#">
                                            Who We Are
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/why-vrm">
                                        <a data-cursor="pointer" href="#">
                                            Why VRM Softcom
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/enquiry-form">
                                        <a data-cursor="pointer" href="#">
                                            Let's Connect
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <h4>@2024 All the Copyright Reserved.</h4>
                        <ul className="footer-links">
                            <li>
                                <a href="">Privacy Policy </a>
                            </li>
                            <li>
                                <a href="">Terms &amp; Condition </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            {/* footer section end*/}
        </>

    );
}

export default Web;
