import { Link } from "react-router-dom";
import React, { useEffect } from 'react';

const StaticWebsite = () => {
    useEffect(() => {
        // Trigger the click event on the first tab button
        document.getElementById("defaultOpen").click();
    }, []);


    const openCity = (evt, cityName) => {
        const tabcontentstatic = document.getElementsByClassName("tabcontentstatic");
        for (let i = 0; i < tabcontentstatic.length; i++) {
            tabcontentstatic[i].style.display = "none";
        }
        const tablinks = document.getElementsByClassName("tablinks");
        for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].classList.remove("active");
        }
        const cityElement = document.getElementById(cityName);
        if (cityElement) {
            cityElement.style.display = "block";
            evt.currentTarget.classList.add("active");
        }
    };


    return (
        <>
            <>
                {/* home section start */}
                <div className="static-screen-section1">
                    <header>
                        <nav className="navbar">
                            <Link to='/'>
                                <div className="logo">
                                    <img src="assets/images/menu/logo.png" height="61px" alt="" />
                                </div>
                            </Link>
                            <Link to='/menu'>
                                <div className="more">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={47}
                                        height={10}
                                        viewBox="0 0 47 10"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </Link>
                        </nav>
                    </header>
                    <div className="static-screen-sec1-content" style={{ color: "#FFF" }}>
                        <h3>SERVICES / WEB DEVELOPMENT / STATIC PAGE</h3>
                        <div style={{ height: 1, width: "100%", backgroundColor: "#fff" }} />
                        <div className="row align-items-center">
                            <div className="col-7">
                                <h2>
                                    Static Website
                                </h2>
                                <p>
                                    Static websites are Ideal for businesses looking for simplicity and quick loading times.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* home section end */}



                {/* static content section start */}
                <div className="static-content-section">
                    <div className="first-heading">
                        <h1>Bring clarity to your website</h1>
                        <h2>Static websites are Ideal for businesses looking for simplicity and quick loading times.</h2>
                    </div>
                    <img src="assets/images/static/top-img.png" className="static-top-img" alt="" />
                    <div className="first-heading">
                        <h2>200,000+ teams have found focus with Slite</h2>
                        <div className="row text-center">
                            <div className="col">
                                <img src="assets/images/static/logo1.png" alt="" />
                            </div>
                            <div className="col">
                                <img src="assets/images/static/logo2.png" alt="" />
                            </div>
                            <div className="col">
                                <img src="assets/images/static/logo3.png" alt="" />
                            </div>
                            <div className="col">
                                <img src="assets/images/static/logo4.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="static-tabbar-section">
                        <h1>A home for all of your knowledge.</h1>
                        <h2>Project, knowledge base, process - bring all your work to life in Docs.</h2>
                        <div className="row justify-content-md-evenly align-items-center" style={{ width: "100%", marginTop: "60px" }}>
                            <div className="col-2">
                                <div className="tab-static">
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab1')}
                                        id="defaultOpen"
                                    >
                                        <span>Wiki</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab2')}
                                        id="btn"
                                    >
                                        <span>Projects</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab3')}
                                        id="btn"
                                    >
                                        <span>Processes</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab4')}
                                        id="btn"
                                    >
                                        <span>Onboarding</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab5')}
                                        id="btn"
                                    >
                                        <span>Meetings</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab6')}
                                        id="btn"
                                    >
                                        <span>Team updates</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab7')}
                                        id="btn"
                                    >
                                        <span>Team updates</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab8')}
                                        id="btn"
                                    >
                                        <span>User research</span>
                                    </button>
                                    <button
                                        className="tablinks"
                                        onClick={(evt) => openCity(evt, 'tab9')}
                                        id="btn"
                                    >
                                        <span>More →</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-9">
                                <div id="tab1" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                                <div id="tab2" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                                <div id="tab3" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                                <div id="tab4" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                                <div id="tab5" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                                <div id="tab6" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                                <div id="tab7" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                                <div id="tab8" className="tabcontentstatic">
                                    <img src="assets/images/static/wiki.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="middle-heading">
                        <h1>Beat the blank canvas with Templates</h1>
                        <h2>Ready-to-use Templates for product specs, company handbooks, meeting notes and more.</h2>
                        <button>Browse templates</button>
                    </div>
                    <div id="static-photobanner">
                        <div className="photobanner">
                            <div className="static-services-box">
                                <h1>Talent<br /> acquisition</h1>
                                <img src="assets/images/static/1.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Stand-up <br />meeting</h1>
                                <img src="assets/images/static/2.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Creative <br />brief</h1>
                                <img src="assets/images/static/3.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Employee <br />handbook</h1>
                                <img src="assets/images/static/4.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Weekly <br />reviews</h1>
                                <img src="assets/images/static/5.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Product <br />planning</h1>
                                <img src="assets/images/static/6.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Onboarding <br />checklist</h1>
                                <img src="assets/images/static/7.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Product <br />specs</h1>
                                <img src="assets/images/static/8.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Talent<br /> acquisition</h1>
                                <img src="assets/images/static/1.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Stand-up <br />meeting</h1>
                                <img src="assets/images/static/2.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Creative <br />brief</h1>
                                <img src="assets/images/static/3.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Employee <br />handbook</h1>
                                <img src="assets/images/static/4.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Weekly <br />reviews</h1>
                                <img src="assets/images/static/5.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Product <br />planning</h1>
                                <img src="assets/images/static/6.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Onboarding <br />checklist</h1>
                                <img src="assets/images/static/7.png" alt="" />
                            </div>
                            <div className="static-services-box">
                                <h1>Product <br />specs</h1>
                                <img src="assets/images/static/8.png" alt="" />
                            </div>
                        </div>
                    </div>


                    <div className="static-types">
                        <h5>Types of Static Websites</h5>
                        {/* <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h6> */}
                    </div>

                    <div id="static-types-photobanner">
                        <div className="types-photobanner">
                            <div className="static-photo-box">
                                <img src="assets/images/dynamic/websites/social/1.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/books.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/chemical.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/dairy.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/footware.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/fruits.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/dynamic/websites/social/1.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/books.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/chemical.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/dairy.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/footware.png" alt="" />
                            </div>
                            <div className="static-photo-box">
                                <img src="assets/images/ecommerce/website/fruits.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* static content section end */}



                {/* contact us start */}
                <section className="info-section section-b-space">
                    <div className="container">
                        <div
                            className="info-box"
                            style={{
                                border: "none",
                                backgroundImage: "url(assets/images/service/footer.svg)",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "start",
                                flexDirection: "column",
                                textAlign: "start"
                            }}
                            data-aos="fade-in"
                            data-aos-duration={1000}
                            data-aos-delay={100}
                        >
                            <h2 style={{ fontFamily: "Inter" }}>Transformation starts here</h2>
                            <p style={{ width: "100%", fontFamily: "Inter" }}>
                                Ready to begin your journey towards a greater future? <br />
                                Get in touch to discover how we can help
                            </p>
                            <div className="row align-items-center mt-4 get-touch">
                                <div className="col-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={46}
                                        height={41}
                                        viewBox="0 0 46 41"
                                        fill="none"
                                    >
                                        <rect
                                            x={5}
                                            width={41}
                                            height={41}
                                            rx="20.5"
                                            fill="#51868C"
                                            fillOpacity="0.5"
                                        />
                                        <rect width={41} height={41} rx="20.5" fill="white" />
                                        <path
                                            d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                            fill="#51868C"
                                        />
                                        <path
                                            d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                            fill="#51868C"
                                        />
                                    </svg>
                                </div>
                                <div className="col-9">
                                    <Link to='/enquiry-form'>
                                        <span
                                            style={{
                                                color: "#FFF",
                                                textAlign: "center",
                                                fontFamily: "Roboto Mono",
                                                fontSize: 18,
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Get in touch
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="get-touch-res">
                                <Link to='/enquiry-form'>
                                    <button>
                                        Get in touch
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* contact us end */}
                {/* footer section start*/}
                <footer>
                    <div className="container">
                        <div className="footer-row">
                            <div className="footer-main">
                                <a href="#" className="footer-logo">
                                    <img src="#" className="img-fluid" alt="" />
                                </a>
                                <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Enter your mail"
                                        />
                                        <a href="#" data-cursor="pointer" className="btn-basic">
                                            Subscribe
                                        </a>
                                    </div>
                                </form>
                            </div>
                            <div className="link-section">
                                <div className="footer-title">Quick Link</div>
                                <div className="footer-content">
                                    <ul>
                                        <li>
                                            <Link to='/'>
                                                <a data-cursor="pointer" href="#">
                                                    Home
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/services'>
                                                <a data-cursor="pointer" href="#">
                                                    Service
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <a data-cursor="pointer" href="#">
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <Link to='/enquiry-form'>
                                                <a data-cursor="pointer" href="#">
                                                    Contact us
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="link-section">
                                <div className="footer-title">Our service</div>
                                <div className="footer-content">
                                    <ul>
                                        <li>
                                            <Link to="/web">
                                                <a data-cursor="pointer" href="#">
                                                    Web Development
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/ecommerce">
                                                <a data-cursor="pointer" href="#">
                                                    E-commerce Development
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/software">
                                                <a data-cursor="pointer" href="#">
                                                    Software Development
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/ui-ux">
                                                <a data-cursor="pointer" href="#">
                                                    UI & UX
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="link-section">
                                <div className="footer-title">Our company</div>
                                <div className="footer-content">
                                    <ul>
                                        <li>
                                            <Link to="/need-consultation">
                                                <a data-cursor="pointer" href="#">
                                                    Need Consultation
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/who-are-we">
                                                <a data-cursor="pointer" href="#">
                                                    Who We Are
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/why-vrm">
                                                <a data-cursor="pointer" href="#">
                                                    Why VRM Softcom
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/enquiry-form">
                                                <a data-cursor="pointer" href="#">
                                                    Let's Connect
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-copyright">
                            <h4>@2024 All the Copyright Reserved.</h4>
                            <ul className="footer-links">
                                <li>
                                    <a href="">Privacy Policy </a>
                                </li>
                                <li>
                                    <a href="">Terms &amp; Condition </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
                {/* footer section end*/}
            </>

        </>
    );
}

export default StaticWebsite;
