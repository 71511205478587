import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import $ from "jquery";



const Ecommerce = () => {

  useEffect(() => {
    // Initially hide all items except the first three
    $('.row.text-center > .col-md-4:nth-child(n+7)').hide();

    // Click event for the "Show more" button
    $('#showMoreBtn').click(function () {
      $('.row.text-center > .col-md-4:nth-child(n+7)').slideToggle();
      var buttonText = $(this).text();
      $(this).text(buttonText === 'Show more' ? 'Show less' : 'Show more');
    });
  }, []);
  useEffect(() => {
    // Trigger the click event on the first tab button
    document.getElementById("defaultOpen").click();
  }, []);
  useEffect(() => {
    // Trigger the click event on the first tab button
    document.getElementById("defaultOpen").click();
  }, []);

  const openCity = (evt, cityName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.classList.add("active");
  };
  return (
    <>
      {/* home section start */}
      <div
        className="service-screen-section1"
        style={{ backgroundImage: "url(assets/images/gifs/ecommerce.gif)" }}
      >
        <header>
          <nav className="navbar">
            <Link to='/'>
              <div className="logo">
                <img src="assets/images/menu/logo.png" height="61px" alt="" />
              </div>
            </Link>
            <Link to='/menu'>
              <div className="more">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={47}
                  height={10}
                  viewBox="0 0 47 10"
                  fill="none"
                >
                  <path
                    d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                    fill="white"
                  />
                  <path
                    d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                    fill="white"
                  />
                  <path
                    d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </nav>
        </header>
        <div className="service-screen-sec1-content">
          <h3>SERVICES / Ecommerce</h3>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#FFF",
              marginTop: 4
            }}
          />
          <h1>
            Expanding The Wings <br /> Over The Globe
          </h1>
          <Link to='/enquiry-form'>
            <div
              className="row align-items-center mt-4 get-touch">
              <div className="col-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={46}
                  height={41}
                  viewBox="0 0 46 41"
                  fill="none"
                >
                  <rect
                    x={5}
                    width={41}
                    height={41}
                    rx="20.5"
                    fill="#51868C"
                    fillOpacity="0.5"
                  />
                  <rect width={41} height={41} rx="20.5" fill="white" />
                  <path
                    d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                    fill="#51868C"
                  />
                  <path
                    d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                    fill="#51868C"
                  />
                </svg>
              </div>
              <div className="col-9">
                <span
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Roboto Mono",
                    fontSize: 18,
                    fontStyle: "normal",
                    fontWeight: 400,
                  }}
                >
                  Get in touch
                </span>
              </div>
            </div>
          </Link>
          <div className="get-touch-res">
            <Link to='/enquiry-form'>
              <button>
                Get in touch
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* home section end */}



      {/* ecommerce about us start */}
      <div className="ecommerce-about-us">
        <div className="ecommerce-about-us-content">
          <div>
            <h2>
              {" "}
              <span>About</span> E-Commerce
            </h2>
            <p>
              E-commerce, short for electronic commerce, refers to the buying and selling of goods and services over the internet. <br /><br /> E-commerce platforms facilitate transactions, payments, and communication between buyers and sellers. It has transformed the way businesses operate, offering a global marketplace accessible 24/7. From online retail giants to individual sellers, e-commerce has democratized commerce, allowing businesses of all sizes to reach a wider audience and conduct transactions efficiently.
            </p>
          </div>
          <img src="assets/images/ecommerce/about.png" alt="" />
          {/* <div class="ecommerce-about-img">
          <img src="assets/images/ecommerce/about.png" alt="">
      </div> */}
        </div>
      </div>
      {/* ecommerce about us end */}



      {/* ecommerce need sec start */}
      <div className="ecommerce-need-sec">
        <div className="ecommerce-need-sec-content">
          <h1><span style={{ borderBottom: "4px solid #1E2222" }}>Need</span> of E-commerce Development</h1>
          <div className="row align-items-start">
            <div className="col">
              <div className="ecommerce-need-box">
                <img src="assets/images/mobile/global-marketing.png" alt="" />
                <h2>Global Reach</h2>
                <p>E-commerce breaks geographical barriers, allowing businesses to reach customers worldwide, expanding their market reach and potential customer base exponentially.</p>
              </div>
            </div>
            <div className="col">
              <div className="ecommerce-need-box">
                <img src="assets/images/mobile/24-7.png" alt="" />
                <h2>24/7 Accessibility </h2>
                <p>Unlike traditional brick-and-mortar stores, e-commerce websites are accessible round the clock, providing convenience to customers and enabling sales even during non-business hours.</p>
              </div>
            </div>
            <div className="col">
              <div className="ecommerce-need-box">
                <img src="assets/images/mobile/cost-effectiveness.png" alt="" />
                <h2>Cost-Effectiveness </h2>
                <p>Setting up and maintaining an e-commerce store is often more cost-effective than establishing a physical retail presence, with lower overhead costs and reduced expenses on rent, utilities, and staffing.</p>
              </div>
            </div>
            <div className="col">
              <div className="ecommerce-need-box">
                <img src="assets/images/ecommerce/data-analysis.png" alt="" />
                <h2>Data-Driven Insights</h2>
                <p>E-commerce platforms provide valuable insights into customer behaviour, preferences, and purchasing patterns through analytics tools, enabling businesses to tailor their marketing strategies and offerings accordingly.</p>
              </div>
            </div>
            <div className="col">
              <div className="ecommerce-need-box">
                <img src="assets/images/mobile/scalability.png" alt="" />
                <h2>Scalability </h2>
                <p>E-commerce websites can easily scale to accommodate growing business needs, whether it involves expanding product lines, reaching new markets, or handling increased website traffic.</p>
              </div>
            </div>
            <div className="col">
              <div className="ecommerce-need-box">
                <img src="assets/images/ecommerce/experience.png" alt="" />
                <h2>Improved Customer Experience</h2>
                <p>E-commerce development allows businesses to enhance the overall customer experience with features like personalized recommend- ations, easy checkout processes, and resp- onsive customer support, fostering customer loyalty and satisfaction.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ecommerce need sec end */}




      {/* web type start */}
      <div className="ui-ux-tabs">
        <div className="ui-ux-content">
          <h3 className="mb-5">Check Our Latest Product’s</h3>
          <div className="tab">
            <button
              className="tablinks"
              onClick={(evt) => openCity(evt, 'tab1')}
              id="defaultOpen"  // Ensure this ID matches with the one used in useEffect
            >
              All
            </button>
            <button
              className="tablinks"
              onClick={(evt) => openCity(evt, 'tab2')}
              id="tab2-btn"
            >
              Business
            </button>
            <button
              className="tablinks"
              onClick={(evt) => openCity(evt, 'tab3')}
              id="tab3-btn"
            >
              Construction
            </button>
            <button
              className="tablinks"
              onClick={(evt) => openCity(evt, 'tab4')}
              id="tab4-btn"
            >
              Electronics
            </button>
            <button
              className="tablinks"
              onClick={(evt) => openCity(evt, 'tab5')}
              id="tab5-btn"
            >
              Finance
            </button>
          </div>

          <div id="tab1" className="tabcontent">
            <div className="row text-center">
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/books.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="src/modules/website.html" className="service-ani-text">
                  <h5>Home Books</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/chemical.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Chemical</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/construction.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Education</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/consultant.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Consultant</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/cosmetic.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Cosmetic</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/dairy.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Dairy</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/electronics.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Electronics</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/finance.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Finance</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/footware.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Footware</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/fruits.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Fruits</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/garments.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Garments</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/grocery.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home Grocerys</h5>
                </a>
              </div>
            </div>
          </div>
          <div id="tab2" className="tabcontent">
            <div className="row text-center">
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/books.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Books</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/cosmetic.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Cosmetic</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/dairy.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Dairy</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/footware.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Footware</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/fruits.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Fruits</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/garments.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Garments</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website/grocery.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Grocerys</h5>
                </a>
              </div>
            </div>
          </div>
          <div id="tab3" className="tabcontent">
            <div className="row text-center">
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/Construction/18.png"
                      alt="example"
                    />
                  </div>
                </a>
                <Link to="/image-gallery">
                  <a href="src/modules/website-images/Construction" target="_blank" className="service-ani-text">
                    <h5>Home</h5>
                  </a>
                </Link>

              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/Construction/21.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/Construction/15.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/Construction/6.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/Construction/12.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/Construction/20.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
            </div>
          </div>
          <div id="tab4" className="tabcontent">
            <div className="row text-center">
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/electronics/1.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Blog</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/electronics/2.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Products</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/electronics/9.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/electronics/11.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Product deatils</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/electronics/16.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Cart</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/electronics/5.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Contact Us</h5>
                </a>
              </div>
            </div>
          </div>
          <div id="tab5" className="tabcontent">
            <div className="row text-center">
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/finance/10.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/finance/1.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>About us</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/finance/19.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Services</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/finance/5.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Case Studies</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/finance/8.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Home</h5>
                </a>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12 ">
                <a href="#" className="container-animation">
                  <div className="demo-item">
                    <img
                      className="s-img-switch"
                      src="assets/images/ecommerce/website-images/finance/3.png"
                      alt="example"
                    />
                  </div>
                </a>
                <a href="#" className="service-ani-text">
                  <h5>Blog List</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="row text-center mt-5">
            <div className="col mt-5">
              <button
                id="showMoreBtn"
                className="btn btn-primary"
              >
                Show more
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* web type end */}

      {/* contact us start */}
      <section className="info-section section-b-space">
        <div className="container">
          <div
            className="info-box"
            style={{
              border: "none",
              backgroundImage: "url(assets/images/service/footer.svg)",
              textAlign: "start"
            }}
            data-aos="fade-in"
            data-aos-duration={1000}
            data-aos-delay={100}
          >
            <h2 style={{ fontFamily: "Inter", marginBottom: 5 }}>
              Transformation starts here
            </h2>
            <p style={{ width: "100%", fontFamily: "Inter" }}>
              let's build your vision.
            </p>
            <Link to='/enquiry-form'>
              <div className="row align-items-center mt-4 mb-4 get-touch">
                <div className="col-md-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={46}
                    height={41}
                    viewBox="0 0 46 41"
                    fill="none"
                  >
                    <rect
                      x={5}
                      width={41}
                      height={41}
                      rx="20.5"
                      fill="#51868C"
                      fillOpacity="0.5"
                    />
                    <rect width={41} height={41} rx="20.5" fill="white" />
                    <path
                      d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                      fill="#51868C"
                    />
                    <path
                      d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                      fill="#51868C"
                    />
                  </svg>
                </div>
                <div className="col-md-auto">
                  <span
                    style={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Roboto Mono",
                      fontSize: 18,
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    Get in touch
                  </span>
                </div>
              </div>
            </Link>
            <div className="get-touch-res">
              <Link to='/enquiry-form'>
                <button>
                  Get in touch
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* contact us end */}
      {/* footer section start*/}
      <footer>
        <div className="container">
          <div className="footer-row">
            <div className="footer-main">
              <a href="#" className="footer-logo">
                <img src="#" className="img-fluid" alt="" />
              </a>
              <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
              <form>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter your mail"
                  />
                  <a href="#" data-cursor="pointer" className="btn-basic">
                    Subscribe
                  </a>
                </div>
              </form>
            </div>
            <div className="link-section">
              <div className="footer-title">Quick Link</div>
              <div className="footer-content">
                <ul>
                  <li>
                    <Link to='/'>
                      <a data-cursor="pointer" href="#">
                        Home
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to='/services'>
                      <a data-cursor="pointer" href="#">
                        Service
                      </a>
                    </Link>
                  </li>
                  <li>
                    <a data-cursor="pointer" href="#">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <Link to='/enquiry-form'>
                      <a data-cursor="pointer" href="#">
                        Contact us
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="link-section">
              <div className="footer-title">Our service</div>
              <div className="footer-content">
                <ul>
                  <li>
                    <Link to="/web">
                      <a data-cursor="pointer" href="#">
                        Web Development
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecommerce">
                      <a data-cursor="pointer" href="#">
                        E-commerce Development
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/software">
                      <a data-cursor="pointer" href="#">
                        Software Development
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-ux">
                      <a data-cursor="pointer" href="#">
                        UI & UX
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="link-section">
              <div className="footer-title">Our company</div>
              <div className="footer-content">
                <ul>
                  <li>
                    <Link to="/need-consultation">
                      <a data-cursor="pointer" href="#">
                        Need Consultation
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/who-are-we">
                      <a data-cursor="pointer" href="#">
                        Who We Are
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/why-vrm">
                      <a data-cursor="pointer" href="#">
                        Why VRM Softcom
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/enquiry-form">
                      <a data-cursor="pointer" href="#">
                        Let's Connect
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <h4>@2024 All the Copyright Reserved.</h4>
            <ul className="footer-links">
              <li>
                <a href="">Privacy Policy </a>
              </li>
              <li>
                <a href="">Terms &amp; Condition </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {/* footer section end*/}
    </>


  );
}

export default Ecommerce;
