import { createSlice } from '@reduxjs/toolkit';


const initialState = { email: null, name: null, token: null, profilePic: null, cart: [] }

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.profilePic = action.payload.profilePic;
            localStorage.setItem("token", action.payload.token);
        },
        refreshTokenSuccess(state, action) {
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.profilePic = action.payload.profilePic;
            state.gender = action.payload.gender;
            localStorage.setItem("token", action.payload.token);
        },
        refreshTokenFailed(state, action) {
            state.token = null;
            state.email = null;
            state.name = null;
            state.profilePic = null;
            state.gender = null;
            localStorage.removeItem("token");

        },
        logoutSuccess(state) {
            state.token = null;
            state.email = null;
            state.name = null;
            state.profilePic = null;
            state.gender = null;
            localStorage.removeItem("token");
        },
        loadCart(state, action) {
            state.cart = action.payload;
        },
        clearCart(state) {
            state.cart = [];
        }
    },
})

export const { loginSuccess, refreshTokenSuccess, logoutSuccess, refreshTokenFailed , loadCart, clearCart} = userSlice.actions
export default userSlice.reducer
