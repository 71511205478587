import {Route, Routes} from "react-router-dom";
import toast, {Toaster} from 'react-hot-toast';
import Base from "./modules/Base";
import Home from "./modules/Home";
import Menu from "./modules/Menu";
import Services from "./modules/Services";
import UiUx from "./modules/UiUx";
import Software from "./modules/Software";
import Ecommerce from "./modules/Ecommerce";
import EnquiryForm from "./modules/EnquiryForm";
import ERP from "./modules/Erp";
import Mobile from "./modules/Mobile";
import Web from "./modules/Web";
import Landing from "./modules/Landing";
import NeedConsultation from "./modules/NeedConsultation";
import WhoAreWe from "./modules/WhoAreWe";
import WhyVRM from "./modules/WhyVrm";
import ScrollToTop from "./components/ScrollToTop";
import StockManagement from "./modules/StockManagement";
import InventoryManagement from "./modules/InventoryManagement";
import HospitalManagement from "./modules/HospitalManagement";
import HostelManagement from "./modules/HostelManagement";
import BillingManagement from "./modules/BillingManagement";
import HRPayRole from "./modules/HRPayRole";
import ImageGallery from "./modules/ecommerce-websites/ImageGallery";
import DynamicWebsite from "./modules/DynamicWebsite";
import StaticWebsite from "./modules/StaticWebsite";

function App() {
    return (
        <>
        <ScrollToTop/>
            <Routes>
               
                <Route path="/" element={
                    <Base children={<Home/>}/>
                }/>
                <Route path="/menu" element={
                    <Base children={<Menu/>}/>
                }/>
                <Route path="/services" element={
                    <Base children={<Services/>}/>
                }/>
                <Route path="/ecommerce" element={
                    <Base children={<Ecommerce/>}/>
                }/>
                <Route path="/enquiry-form" element={
                    <Base children={<EnquiryForm/>}/>
                }/>
                <Route path="/erp" element={
                    <Base children={<ERP/>}/>
                }/>
                <Route path="/mobile" element={
                    <Base children={<Mobile/>}/>
                }/>
                <Route path="/software" element={
                    <Base children={<Software/>}/>
                }/>
                <Route path="/ui-ux" element={
                    <Base children={<UiUx/>}/>
                }/>
                <Route path="/web" element={
                    <Base children={<Web/>}/>
                }/>
                <Route path="/landing" element={
                    <Base children={<Landing/>}/>
                }/>
                <Route path="/need-consultation" element={
                    <Base children={<NeedConsultation/>}/>
                }/>
                <Route path="/who-are-we" element={
                    <Base children={<WhoAreWe/>}/>
                }/>
                <Route path="/why-vrm" element={
                    <Base children={<WhyVRM/>}/>
                }/>
                <Route path="/stock-management" element={
                    <Base children={<StockManagement/>}/>
                }/>
                <Route path="/inventory-management" element={
                    <Base children={<InventoryManagement/>}/>
                }/>
                <Route path="/hospital-management" element={
                    <Base children={<HospitalManagement/>}/>
                }/>
                <Route path="/hostel-management" element={
                    <Base children={<HostelManagement/>}/>
                }/>
                <Route path="/billing-management" element={
                    <Base children={<BillingManagement/>}/>
                }/>
                <Route path="/hr-pay-role" element={
                    <Base children={<HRPayRole/>}/>
                }/>
                <Route path="/image-gallery" element={
                    <Base children={<ImageGallery/>}/>
                }/>
                <Route path="/dynamic" element={
                    <Base children={<DynamicWebsite/>}/>
                }/>
                <Route path="/static" element={
                    <Base children={<StaticWebsite/>}/>
                }/>
                
            </Routes>
            <Toaster/>
        </>
    );
}

export default App;
