import { combineReducers } from '@reduxjs/toolkit'
import product from "./product/reducers";
import user from "./user/reducers";

const rootReducer = combineReducers({
    product: product,
    user: user,
})

export default rootReducer
