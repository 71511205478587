import { Link } from "react-router-dom";
import React, { useEffect } from 'react';
import $ from "jquery";

const DynamicWebsite = () => {
    useEffect(() => {
        // Initially hide all items except the first three
        $('.row.text-center > .col-md-4:nth-child(n+7)').hide();

        // Click event for the "Show more" button
        $('#showMoreBtn').click(function () {
            $('.row.text-center > .col-md-4:nth-child(n+7)').slideToggle();
            var buttonText = $(this).text();
            $(this).text(buttonText === 'Show more' ? 'Show less' : 'Show more');
        });
    }, []);
    return (
        <>
            <>
                {/* home section start */}
                <div className="dynamic-screen-section1">
                    <header>
                        <nav className="navbar">
                            <Link to='/'>
                                <div className="logo">
                                    <img src="assets/images/menu/logo.png" height="61px" alt="" />
                                </div>
                            </Link>
                            <Link to='/menu'>
                                <div className="more">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={47}
                                        height={10}
                                        viewBox="0 0 47 10"
                                        fill="none"
                                    >
                                        <path
                                            d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </Link>
                        </nav>
                    </header>
                    <div className="dynamic-screen-sec1-content" style={{ color: "#FFF" }}>
                        <h3>SERVICES / WEB DEVELOPMENT / DYNAMIC PAGE</h3>
                        <div style={{ height: 1, width: "100%", backgroundColor: "#fff" }} />
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h2>
                                    Why Dynamic Pages <br />are Important
                                </h2>
                                <p>
                                    Dynamic web pages are crucial because they offer personalized, interactive experiences tailored to individual users. <br /><br /> By adapting content based on user input or database queries, dynamic pages enhance engagement, improve usability, and enable real-time updates, ensuring websites remain relevant and responsive to the ever-changing needs of users and businesses alike.
                                </p>
                            </div>
                            <div className="col-5">
                                <img src="assets/images/landing/website-1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* home section end */}


                {/* dynamic sec2 start */}
                <div className="dynamic-sec2">
                    <div className="dynamic-sec2-top">
                        <div className="col-4">
                            <h3>Make even more money</h3>
                            <h1>The possibilities are endless</h1>
                            <p>Our active developer community releases new daily! Have a great idea for a fitness, a news, or your own niche marketplace? Browse our website bring your idea to life!</p>
                            <button>Get started</button>
                        </div>
                        <img src="assets/images/dynamic/img1.png" alt="" />
                    </div>
                    <img src="assets/images/dynamic/img2.png" className="sec2-img" alt="" />
                </div>
                {/* dynamic sec2 end */}




                {/* dynamic card img section start */}
                <div className="dynamic-card-img">
                    <img src="assets/images/dynamic/img3.png" className="sec2-img" alt="" />
                    <img src="assets/images/dynamic/shadow.png" className="dynamic-shadow" alt="" />
                </div>
                {/* dynamic card img section end */}





                {/* dynamic services start */}
                <div className="dynamic-services">

                    <div className="dynamic-services-content">
                        <div className="row justify-content-md-evenly align-items-center">
                            <div className="col-4">
                                <h6>Designed for developers</h6>
                                <h1>Ship more quickly with powerful and</h1>
                                <h2>easy-to-use APIs</h2>
                                <span>Whop's platform streamlines app development with integrated user authentication, seamless payment processing, and efficient customer management. Designed for an optimal developer experience, our tools simplify complex backend processes, enabling you to focus on bringing your idea to life on a single, adaptable platform.</span><br />
                                <button>Read the docs</button>
                            </div>
                            <div className="col-7">
                                <img src="assets/images/dynamic/img4.png" width="100%" alt="" />
                            </div>
                        </div>
                        <div className="row justify-content-md-between align-items-start" style={{ marginTop: "80px" }}>
                            <div className="col-md-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                    <path d="M60 28H44C41.7909 28 40 29.7909 40 32V60C40 62.2091 41.7909 64 44 64H60C62.2091 64 64 62.2091 64 60V32C64 29.7909 62.2091 28 60 28Z" fill="#FF6243" fill-opacity="0.2" stroke="#FF6243" stroke-width="2" />
                                    <path d="M10 48V12C10 9.79086 11.7909 8 14 8H56C58.2091 8 60 9.79086 60 12V23M10 48L6 61M10 48H35M6 61V62C6 63.6569 7.34315 65 9 65H35M6 61H35" stroke="#FF6243" stroke-width="2" />
                                    <path d="M49 33H55" stroke="#FF6243" stroke-width="2" stroke-linejoin="round" />
                                    <path d="M36 40H21C19.3431 40 18 38.6569 18 37V19C18 17.3431 19.3431 16 21 16H49C50.6569 16 52 17.3431 52 19V24" stroke="#FBFBFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.5 4" />
                                </svg>
                                <h3>Sell your own apps</h3>
                                <h4>On Whop, you’ll be able to build your own applications or software that you can sell directly to your customers. Launch SaaS apps in minutes.</h4>
                            </div>
                            <div className="col-md-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                    <path d="M8 12C8 9.79086 9.79086 8 12 8H28C30.2091 8 32 9.79086 32 12V36C32 38.2091 30.2091 40 28 40H12C9.79086 40 8 38.2091 8 36V12Z" fill="#FF6243" fill-opacity="0.2" stroke="#FF6243" stroke-width="2" />
                                    <path d="M38 12C38 9.79086 39.7909 8 42 8H60C62.2091 8 64 9.79086 64 12V17C64 19.2091 62.2091 21 60 21H42C39.7909 21 38 19.2091 38 17V12Z" fill="#FF6243" fill-opacity="0.2" stroke="#FF6243" stroke-width="2" />
                                    <path d="M64 46V12C64 9.79086 62.2091 8 60 8H12C9.79086 8 8 9.79086 8 12V60C8 62.2091 9.79086 64 12 64H39" stroke="#FF6243" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.5 4" />
                                    <path d="M46 42L49 64L54 56H64L46 42Z" stroke="white" stroke-width="2" stroke-linejoin="round" />
                                </svg>
                                <h3>Build apps for sellers</h3>
                                <h4>Don’t have an idea for your own app? Build/sell apps that
                                    other sellers can take advantage of. Whether it be a dog-
                                    walking app or a dispute-fighting app, build apps for
                                    businesses!</h4>
                            </div>
                            <div className="col-md-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                    <path d="M24 16C24 11.5817 27.5817 8 32 8H56C60.4183 8 64 11.5817 64 16V26C64 30.4183 60.4183 34 56 34H48L38 42V34H32C27.5817 34 24 30.4183 24 26V16Z" stroke="#FF6243" stroke-width="2" />
                                    <path d="M33 17H55" stroke="#FBFBFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M33 25H44" stroke="#FBFBFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.5 4" />
                                    <path d="M20 47C22.7614 47 25 44.7614 25 42C25 39.2386 22.7614 37 20 37C17.2386 37 15 39.2386 15 42C15 44.7614 17.2386 47 20 47Z" fill="#FF6243" fill-opacity="0.2" stroke="#FF6243" stroke-width="2" />
                                    <path d="M12.0652 55.8695C13.251 53.498 15.6748 52 18.3262 52H21.9794C24.4691 52 26.7714 53.3224 28.0259 55.4729L33 64H8L12.0652 55.8695Z" fill="#FF6243" fill-opacity="0.2" stroke="#FF6243" stroke-width="2" stroke-linejoin="round" />
                                </svg>
                                <h3>Get hired as a freelance dev</h3>
                                <h4>Sellers on Whop are always looking for more development talent to help them scale. You can get hired as a contractor by sellers on Whop directly from the dashboard!</h4>
                            </div>
                        </div>
                        <img src="assets/images/dynamic/img5.png" className="dynamic-services-img" alt="" />
                    </div>
                    <img src="assets/images/dynamic/shadow.png" className="dynamic-shadow" alt="" />
                </div>
                {/* dynamic services end */}


                <div className="dynamic-images-grid">
                    <div className="dynamic-grid-content">
                        <div className="row text-center">
                            <div className="col-md-4 col-sm-6 col-xs-12 ">
                                <a href="#" className="container-animation">
                                    <div className="demo-item">
                                        <img
                                            className="s-img-switch"
                                            src="assets/images/dynamic/websites/corporate/1.png"
                                            width="100%"
                                            alt="example"
                                        />
                                    </div>
                                </a>
                                <a
                                    href="#"
                                    className="service-ani-text"
                                >
                                    <h5>Main Corporate</h5>
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 ">
                                <a href="#" className="container-animation">
                                    <div className="demo-item">
                                        <img
                                            className="s-img-switch"
                                            src="assets/images/dynamic/websites/entertainment/1.png"
                                            width="100%"
                                            alt="example"
                                        />
                                    </div>
                                </a>
                                <a
                                    href="#"
                                    className="service-ani-text"
                                >
                                    <h5>Home Business</h5>
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 ">
                                <a href="#" className="container-animation">
                                    <div className="demo-item">
                                        <img
                                            className="s-img-switch"
                                            src="assets/images/dynamic/websites/entertainment/2.png"
                                            width="100%"
                                            alt="example"
                                        />
                                    </div>
                                </a>
                                <a
                                    href="#"
                                    className="service-ani-text"
                                >
                                    <h5>Home Entertainment</h5>
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 ">
                                <a href="#" className="container-animation">
                                    <div className="demo-item">
                                        <img
                                            className="s-img-switch"
                                            src="assets/images/dynamic/websites/furniture/1.png"
                                            width="100%"
                                            alt="example"
                                        />
                                    </div>
                                </a>
                                <a
                                    href="#"
                                    className="service-ani-text"
                                >
                                    <h5>Home Furniture</h5>
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 ">
                                <a href="#" className="container-animation">
                                    <div className="demo-item">
                                        <img
                                            className="s-img-switch"
                                            src="assets/images/dynamic/websites/social/1.png"
                                            width="100%"
                                            alt="example"
                                        />
                                    </div>
                                </a>
                                <a
                                    href="#"
                                    className="service-ani-text"
                                >
                                    <h5>Home Social</h5>
                                </a>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 ">
                                <a href="#" className="container-animation">
                                    <div className="demo-item">
                                        <img
                                            className="s-img-switch"
                                            src="assets/images/dynamic/websites/technology/1.png"
                                            width="100%"
                                            alt="example"
                                        />
                                    </div>
                                </a>
                                <a
                                    href="#"
                                    className="service-ani-text"
                                >
                                    <h5>Main Technology</h5>
                                </a>
                            </div>
                        </div>
                        <div className="row text-center mt-5">
                            <div className="col mt-5">
                                <button
                                    id="showMoreBtn"
                                    className="btn btn-primary"
                                >
                                    Show more
                                </button>
                            </div>
                        </div>
                    </div>
                </div>





                {/* contact us start */}
                <section className="info-section section-b-space">
                    <div className="container">
                        <div
                            className="info-box"
                            style={{
                                border: "none",
                                backgroundImage: "url(assets/images/service/footer.svg)",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "start",
                                flexDirection: "column",
                                textAlign: "start"
                            }}
                            data-aos="fade-in"
                            data-aos-duration={1000}
                            data-aos-delay={100}
                        >
                            <h2 style={{ fontFamily: "Inter" }}>Transformation starts here</h2>
                            <p style={{ width: "100%", fontFamily: "Inter" }}>
                                Ready to begin your journey towards a greater future? <br />
                                Get in touch to discover how we can help
                            </p>
                            <div className="row align-items-center mt-4 get-touch">
                                <div className="col-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={46}
                                        height={41}
                                        viewBox="0 0 46 41"
                                        fill="none"
                                    >
                                        <rect
                                            x={5}
                                            width={41}
                                            height={41}
                                            rx="20.5"
                                            fill="#51868C"
                                            fillOpacity="0.5"
                                        />
                                        <rect width={41} height={41} rx="20.5" fill="white" />
                                        <path
                                            d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                            fill="#51868C"
                                        />
                                        <path
                                            d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                            fill="#51868C"
                                        />
                                    </svg>
                                </div>
                                <div className="col-9">
                                    <Link to='/enquiry-form'>
                                        <span
                                            style={{
                                                color: "#FFF",
                                                textAlign: "center",
                                                fontFamily: "Roboto Mono",
                                                fontSize: 18,
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Get in touch
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="get-touch-res">
                                <Link to='/enquiry-form'>
                                    <button>
                                        Get in touch
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* contact us end */}
                {/* footer section start*/}
                <footer>
                    <div className="container">
                        <div className="footer-row">
                            <div className="footer-main">
                                <a href="#" className="footer-logo">
                                    <img src="#" className="img-fluid" alt="" />
                                </a>
                                <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Enter your mail"
                                        />
                                        <a href="#" data-cursor="pointer" className="btn-basic">
                                            Subscribe
                                        </a>
                                    </div>
                                </form>
                            </div>
                            <div className="link-section">
                                <div className="footer-title">Quick Link</div>
                                <div className="footer-content">
                                    <ul>
                                        <li>
                                            <Link to='/'>
                                                <a data-cursor="pointer" href="#">
                                                    Home
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/services'>
                                                <a data-cursor="pointer" href="#">
                                                    Service
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <a data-cursor="pointer" href="#">
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <Link to='/enquiry-form'>
                                                <a data-cursor="pointer" href="#">
                                                    Contact us
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="link-section">
                                <div className="footer-title">Our service</div>
                                <div className="footer-content">
                                    <ul>
                                        <li>
                                            <Link to="/web">
                                                <a data-cursor="pointer" href="#">
                                                    Web Development
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/ecommerce">
                                                <a data-cursor="pointer" href="#">
                                                    E-commerce Development
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/software">
                                                <a data-cursor="pointer" href="#">
                                                    Software Development
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/ui-ux">
                                                <a data-cursor="pointer" href="#">
                                                    UI & UX
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="link-section">
                                <div className="footer-title">Our company</div>
                                <div className="footer-content">
                                    <ul>
                                        <li>
                                            <Link to="/need-consultation">
                                                <a data-cursor="pointer" href="#">
                                                    Need Consultation
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/who-are-we">
                                                <a data-cursor="pointer" href="#">
                                                    Who We Are
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/why-vrm">
                                                <a data-cursor="pointer" href="#">
                                                    Why VRM Softcom
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/enquiry-form">
                                                <a data-cursor="pointer" href="#">
                                                    Let's Connect
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-copyright">
                            <h4>@2024 All the Copyright Reserved.</h4>
                            <ul className="footer-links">
                                <li>
                                    <a href="">Privacy Policy </a>
                                </li>
                                <li>
                                    <a href="">Terms &amp; Condition </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
                {/* footer section end*/}
            </>

        </>
    );
}

export default DynamicWebsite;
