

const Base = ({children}) => {
  return (
      <>
          {children}
      </>
  );
}

export default Base;
